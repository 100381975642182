"use client";

import {
  createContext,
  useContext,
  useState,
  useMemo,
  ReactNode,
  useCallback,
} from "react";
import { IncidentFilter, Incident } from "@/types";
import { useSelf } from "@/hooks/useSelf";
import { useIncidentFilter } from "@/hooks/useIncidentFilter";

interface IncidentViewContextType {
  category: any;
  setCategory: (category: any) => void;
  queryParams: Partial<IncidentFilter>;
  filterIncidents: (incidents: Incident[]) => Incident[];
}

const defaultIncidentViewContext: IncidentViewContextType = {
  category: "all",
  setCategory: () => console.warn("setCategory called outside of provider"),
  queryParams: {},
  filterIncidents: (incidents) => incidents,
};

export const IncidentViewContext = createContext<IncidentViewContextType>(
  defaultIncidentViewContext,
);

export function useIncidentView() {
  const context = useContext(IncidentViewContext);

  if (!context) {
    // console.error(
    //   "[IncidentView] useIncidentView called outside of IncidentViewProvider",
    // );
    throw new Error("useIncidentView must be used within IncidentViewProvider");
  }

  // console.log("[IncidentView] Hook accessed:", {
  //   category: context.category,
  //   hasQueryParams: !!context.queryParams,
  //   timestamp: new Date().toISOString(),
  // });

  return context;
}

export function IncidentViewProvider({ children }: { children: ReactNode }) {
  const [category, setCategory] = useState<any>("all");
  const { data: self } = useSelf();
  const { data: incidentFilter } = useIncidentFilter();

  const queryParams = useMemo<Partial<IncidentFilter>>(() => {
    const baseFilters: Partial<IncidentFilter> = {
      limit: 100,
      propertyAddress: true,
      from: incidentFilter?.from,
      to: incidentFilter?.to,
      presets: incidentFilter?.presets,
    };

    if (self?.UserStateSubscriptions?.length) {
      baseFilters.allCountyStateIds = self.UserStateSubscriptions.map(
        (sub: { StateId: any }) => sub.StateId,
      ).join(",");
    }

    return category === "all"
      ? { ...baseFilters, category: "all" }
      : {
          ...baseFilters,
          active: true,
          incidentStatus: "active",
          ...(category === "paged" ? { paged: true } : {}),
          ...(category === "unpaged" ? { paged: false } : {}),
        };
  }, [category, self, incidentFilter]);

  const filterIncidents = useCallback(
    (incidents: Incident[]): Incident[] => {
      if (!incidents?.length) return [];

      switch (category) {
        case "unpaged":
          return incidents.filter((inc) => !inc.paged);
        case "paged":
          return incidents.filter((inc) => inc.paged);
        case "favorited":
        case "archived":
          return incidents;
        default:
          return incidents;
      }
    },
    [category],
  );

  return (
    <IncidentViewContext.Provider
      value={{
        category,
        setCategory,
        queryParams,
        filterIncidents,
      }}
    >
      {children}
    </IncidentViewContext.Provider>
  );
}
