// "use client";

// import createCache from "@emotion/cache";
// import { CacheProvider } from "@emotion/react";

// export function createEmotionCache() {
//   return createCache({ key: "css", prepend: true });
// }

// export function EmotionCacheProvider({
//   children,
// }: {
//   children: React.ReactNode;
// }) {
//   const cache = createEmotionCache();
//   return <CacheProvider value={cache}>{children}</CacheProvider>;
// }

import createCache from "@emotion/cache";

const isBrowser = typeof document !== "undefined";

export function createEmotionCache() {
  let insertionPoint;

  if (isBrowser) {
    const emotionInsertionPoint = document.querySelector<HTMLMetaElement>(
      'meta[name="emotion-insertion-point"]',
    );
    insertionPoint = emotionInsertionPoint ?? undefined;
  }

  return createCache({
    key: "mui-style",
    insertionPoint,
    prepend: true,
  });
}
