"use client";

import { useConfiguration } from "@/hooks/useConfiguration";
import { IncidentFilter } from "@/types";
import { storage } from "@/util/storage";
import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useSearchParams } from "next/navigation";
import { useSelf } from "./useSelf";
import { api } from "@/api";
import { useEffect, useState } from "react";

type Props = Omit<
  UseQueryOptions<IncidentFilter>,
  "queryKey" | "queryFn"
> | void;

export function useIncidentFilter(props: Props) {
  const { data: configuration } = useConfiguration();
  const { data: self } = useSelf();

  const [searchParamsState, setSearchParamsState] = useState<{
    keywords: string;
    location: string;
  }>({
    keywords: "",
    location: "",
  });

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setSearchParamsState({
      keywords: params.get("keywords") || "",
      location: params.get("location") || "",
    });
  }, []);

  return useQuery<IncidentFilter>({
    enabled: !!configuration,
    queryKey: ["filters", "incidents"],
    queryFn: async () => {
      const storedIncidentFilter = await storage.get("incidentFilter");
      let stored = storedIncidentFilter ? JSON.parse(storedIncidentFilter) : {};

      const defaultDates = {
        from: stored.from || dayjs().subtract(12, "hours").toISOString(),
        to: stored.to || dayjs().endOf("day").toISOString(),
      };

      let userPresets: string[] = [];
      if (self?.id) {
        try {
          const userPresetsResponse = await api.get(
            `users/${self.id}/subscriptions/presets`,
          );
          userPresets =
            userPresetsResponse.data?.presets?.map((p: any) => p.label) || [];
        } catch (error) {
          console.error("Error fetching user presets:", error);
        }
      }

      const incidentFilter: IncidentFilter = {
        keywords: searchParamsState.keywords,
        location: searchParamsState.location,
        incidentStatus: "active",
        limit: 100,
        ...defaultDates,
        radius: 30,
        presets: userPresets,
        active: true,
        propertyAddress: true,
        legalDetails: false,
        stateIds: stored.stateIds || [],
        countyInclusions: stored.countyInclusions || [],
        countyExclusions: stored.countyExclusions || [],
        cityInclusions: stored.cityInclusions || [],
        cityExclusions: stored.cityExclusions || [],
        allCountyStateIds: stored.allCountyStateIds || [],
        category: "verified",
      };

      await storage.set("incidentFilter", JSON.stringify(incidentFilter));
      return incidentFilter;
    },
    ...props,
  });
}
