"use client";

import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";

type MenuContextType = {
  menuIsOpen: boolean;
  toggleMenu: () => void;
};

const defaultMenuContext: MenuContextType = {
  menuIsOpen: true,
  toggleMenu: () => console.warn("toggleMenu called outside of provider"),
};

export const MenuContext = createContext<MenuContextType>(defaultMenuContext);

const MENU_STATE_KEY = "menuIsOpen";

export function MenuProvider({ children }: { children: ReactNode }) {
  const [menuIsOpen, setMenuIsOpen] = useState(() => {
    if (typeof window !== "undefined") {
      const saved = localStorage.getItem(MENU_STATE_KEY);
      return saved !== null ? saved === "true" : true;
    }
    return true;
  });

  const toggleMenu = () => {
    setMenuIsOpen((prev) => {
      const newState = !prev;
      localStorage.setItem(MENU_STATE_KEY, String(newState));
      return newState;
    });
  };

  return (
    <MenuContext.Provider value={{ menuIsOpen, toggleMenu }}>
      {children}
    </MenuContext.Provider>
  );
}

export function useMenu() {
  const context = useContext(MenuContext);
  if (context === undefined) {
    throw new Error("useMenu must be used within a MenuProvider");
  }
  return context;
}
