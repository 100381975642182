"use client";

import { queryClient } from "@/util/queryClient";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactNode, useContext, useEffect } from "react";
import { theme } from "@/theme";
import { ThemeProvider } from "@mui/material";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { dayjs } from "@/util/dayjs";
import { Conversation, Message, User } from "@/types";
import { socket } from "@/api/socket";
import { useParams, usePathname, useRouter } from "next/navigation";
import { api } from "@/api/api";
import { produce } from "immer";
import {
  IncidentViewProvider,
  IncidentViewContext,
} from "@/context/incident-context";
import {
  CallTypesProvider,
  CallTypesContext,
} from "@/context/calltype-context";
import { NuqsAdapter } from "nuqs/adapters/next/app";
import { MenuProvider, MenuContext } from "@/context/menu-context";
import { createEmotionCache } from "@/lib/create-emotion-cache";
import { CacheProvider } from "@emotion/react";

type Props = {
  children: ReactNode;
  self?: User | null;
  accessToken?: string;
};

const clientSideEmotionCache = createEmotionCache();

function ProviderContent({ children, self, accessToken }: Props) {
  const router = useRouter();
  const pathName = usePathname();
  const pageParams = useParams();

  const menuContext = useContext(MenuContext);
  const callTypesContext = useContext(CallTypesContext);
  const incidentViewContext = useContext(IncidentViewContext);

  console.log("Checking Providers:");
  console.log("MenuProvider:", menuContext);
  console.log("CallTypesProvider:", callTypesContext);
  console.log("IncidentViewProvider:", incidentViewContext);

  useEffect(() => {
    if (accessToken) {
      const onError = (error: any) => console.error("Socket error", error);
      const onConnect = () => console.log("Socket connected");
      const onDisconnect = () => console.log("Socket disconnected");
      const onConnectTimeout = () =>
        console.error("Socket connection timed out");

      const onSessionDuplicated = async () => {
        await api.post("auth/signOut");
        router.push("/auth/sign-in");
        alert("Your account was signed into from another device");
      };

      socket.on("error", onError);
      socket.on("connect", onConnect);
      socket.on("disconnect", onDisconnect);
      socket.on("connect_timeout", onConnectTimeout);
      socket.on("session.duplicated", onSessionDuplicated);

      socket.auth = {
        token: accessToken,
      };

      socket.connect();

      return () => {
        socket.removeAllListeners();
        socket.disconnect();
      };
    }
  }, [accessToken, router]);

  useEffect(() => {
    const onMessageReceived = ({ message }: { message: Message }) => {
      queryClient.setQueryData<Conversation>(
        [
          "conversations",
          {
            id: message.ConversationId,
          },
        ],
        (storedConversation) =>
          produce(storedConversation, (draft) => {
            draft?.Messages?.unshift?.(message);
          }),
      );

      if (
        (!pathName.startsWith("/dispatch-connect") ||
          pageParams.conversationId !== message.ConversationId) &&
        message.ConversationId !== "dispatch-global"
      ) {
        const image = message.Sender?.Avatar?.url || "/assets/logo.png";

        const notification = new Notification(message.Sender!.fullName, {
          body: message.content,
          lang: "en-US",
          badge: image,
          data: message,
          icon: image,
        });

        notification.addEventListener("click", () => {
          router.push(`/dispatch-connect/${message.ConversationId}`);
        });
      }

      if (pathName === "/dispatch-connect") {
        router.refresh();
      }
    };

    socket.on("conversation.message", onMessageReceived);

    return () => {
      socket.off("conversation.message", onMessageReceived);
    };
  }, [pathName, pageParams, router]);

  return children;
}

function Wrapper({ self, children }: Props) {
  useEffect(() => {
    queryClient.setQueryData(["self"], self);
  }, [self]);

  return children;
}

export function Providers({ children, self, accessToken }: Props) {
  return (
    <CacheProvider value={clientSideEmotionCache}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            dateLibInstance={dayjs}
          >
            <MenuProvider>
              <Wrapper self={self}>
                <ProviderContent self={self} accessToken={accessToken}>
                  {accessToken ? (
                    <CallTypesProvider self={self} accessToken={accessToken}>
                      <IncidentViewProvider>
                        <NuqsAdapter>{children}</NuqsAdapter>
                      </IncidentViewProvider>
                    </CallTypesProvider>
                  ) : (
                    <NuqsAdapter>{children}</NuqsAdapter>
                  )}
                </ProviderContent>
              </Wrapper>
            </MenuProvider>
          </LocalizationProvider>
        </ThemeProvider>
        <ReactQueryDevtools initialIsOpen={false} buttonPosition="top-right" />
      </QueryClientProvider>
    </CacheProvider>
  );
}
