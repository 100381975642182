"use client";

import { Grid, Stack, Typography, BoxProps } from "@mui/material";
import { dayjs } from "@/util";
import { useMenu } from "@/context/menu-context";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { useEffect, useState } from "react";

const TimezoneDebugInfo = ({ userRole }: { userRole: any }) => {
  const [currentTime, setCurrentTime] = useState<string | null>(null);
  const [utcTime, setUtcTime] = useState<string | null>(null);
  const [pstTime, setPstTime] = useState<string | null>(null);
  const [estTime, setEstTime] = useState<string | null>(null);
  const [systemTZ, setSystemTZ] = useState<string | null>(null);

  useEffect(() => {
    const updateTime = () => {
      setCurrentTime(dayjs().format("MM/DD/YYYY HH:mm:ss"));
      setUtcTime(dayjs().utc().format("MM/DD/YYYY HH:mm:ss"));
      setPstTime(
        dayjs().tz("America/Los_Angeles").format("MM/DD/YYYY HH:mm:ss"),
      );
      setEstTime(dayjs().tz("America/New_York").format("MM/DD/YYYY HH:mm:ss"));
      setSystemTZ(dayjs.tz.guess());
    };

    updateTime(); // Set initial values
    const interval = setInterval(updateTime, 1000); // Update every second

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);

  return (
    <Stack
      spacing={1}
      sx={{
        position: "absolute",
        top: "60px",
        right: "10px",
        backgroundColor: "rgba(0,0,0,0.8)",
        color: "white",
        padding: "10px",
        borderRadius: "4px",
        fontSize: "12px",
        zIndex: 1000,
        animation: "fadeAway 3s forwards",
        "@keyframes fadeAway": {
          "0%": { opacity: 1 },
          "80%": { opacity: 1 },
          "100%": { opacity: 0, visibility: "hidden" },
        },
      }}
    >
      <Typography variant="caption">System TZ: {systemTZ}</Typography>
      <Typography variant="caption">
        Current Role: {userRole || "Not Set"}
      </Typography>
      <Typography variant="caption">User Local Time: {currentTime}</Typography>
      <Typography variant="caption">UTC Time: {utcTime}</Typography>
      <Typography variant="caption">PST Time: {pstTime}</Typography>
      <Typography variant="caption">EST Time: {estTime}</Typography>
    </Stack>
  );
};

type Props = BoxProps & {
  initialUser?: any;
};

export function Header({ initialUser, ...props }: Props) {
  const { toggleMenu } = useMenu();
  const userRole = initialUser?.Roles?.[0]?.name;

  return (
    <Grid
      component="nav"
      container
      {...props}
      sx={{
        height: "60px",
        minHeight: "60px",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
        padding: "0 1rem",
        position: "sticky",
        top: 0,
        zIndex: 10,
        backgroundColor: "#FFFFFF",
        borderBottom: "1px solid #CFD8DD",
        ...props.sx,
      }}
    >
      <Grid item xs>
        <IconButton onClick={toggleMenu}>
          <MenuIcon />
        </IconButton>
      </Grid>
      <Grid item xs>
        <Typography textAlign="center" fontWeight="600">
          Fire Notification
        </Typography>
      </Grid>
      <TimezoneDebugInfo userRole={userRole} />
      <Grid item xs></Grid>
    </Grid>
  );
}
